import { Component, effect, inject, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectConnectionsVoucherComponent } from 'libs/direct-connections-voucher/src/lib/components/direct-connections-voucher/direct-connections-voucher.component';
import { DirectConnectionsVoucher } from 'libs/direct-connections-voucher/src/lib/components/direct-connections-voucher/direct-connections-voucher.interface';
import { PdfJsService } from '@angular-monorepo/pdf';
import { CaptureScreenService } from '@angular-monorepo/screenshot';
import { InstructionStepper, LinearStepInfo } from '@angular-monorepo/shared';
import { MediaButton } from '@angular-monorepo/ui';
import { bankTransferPaymentMethodButton, cashPaymentMethodButton, walletPaymentMethodButton } from 'libs/direct-connections-voucher/src/lib/components/direct-connections-voucher-container/direct-connections-voucher.utils';
import { PaymentMethodInstruction } from '@angular-monorepo/api-services';
import { PayinVoucherPdfContainerComponent } from '../payin-voucher-pdf-container/payin-voucher-pdf-container.component';
import { Processors } from '@angular-monorepo/processor-selection';

@Component({
  selector: 'voucher-payin-intructions',
  standalone: true,
  imports: [CommonModule,DirectConnectionsVoucherComponent,PayinVoucherPdfContainerComponent],
  templateUrl: './voucher-payin-intructions.component.html',
  styleUrl: './voucher-payin-intructions.component.scss',
})
export class VoucherPayinIntructionsComponent {
  jsPdfService = inject(PdfJsService)
  screenRecordService = inject(CaptureScreenService)
  @Input() voucherData:any;
  @Input() selectedProcessor:Processors;
  @Input() linearSteps: LinearStepInfo[] = []
  @Input() processors:Processors[] = []
  @Input() layout = 'default'
  directConnectionsVoucherInput: DirectConnectionsVoucher = {} as DirectConnectionsVoucher;
  showTemplate: boolean = false
  
  constructor(){
    effect(() => {
      if (this.screenRecordService.generatedImage()) {
        (() => {
        
          this.downloadImage(this.screenRecordService.generatedImage())
          this.onShowTemplateFunc(false)
          window.location.reload()
         
        })()
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['selectedProcessor']){
      this.initInstructionSteps()
      this.changeProcessorInstructions()
    }
  }

  initInstructionSteps(): void {
    let directConnectionsVoucherData = {
      paymentMethodButtons: [],
      downloadButton: {
        imagesUrl: ['./assets/svg/download-file.svg'],
        label: 'Descargar instrucciones',
        color: 'secondary',
        onClick: () => {
          this.generatePdf()
        },
      },
      instructions: []
    }

    this.directConnectionsVoucherInput = directConnectionsVoucherData as unknown as DirectConnectionsVoucher
  }

  generatePdfByImage(image: string): void {
    const pdfWidth = 210
    let voucherHeight = 300

    if (this.voucherData?.voucherData?.collectorInstructions.length === 1) {
      voucherHeight = 200
    }

    if (this.voucherData?.voucherData?.collectorInstructions.length === 2) {
      voucherHeight = 250
    }

    this.jsPdfService.addImage(
      image,
      'JPEG',
      0, //X posición en el PDF
      0, //Y posición en el PDF
      pdfWidth, //Ancho en el PDF
      voucherHeight
    );

    this.jsPdfService.save('Instrucciones de pago')
  }



  onSetDownloadButtonToLoading(loading: boolean): void {
    this.directConnectionsVoucherInput = {
      ...this.directConnectionsVoucherInput,
      downloadButton: {
        ...this.directConnectionsVoucherInput.downloadButton,
        isLoading: loading,
      },
    }
  }

  generatePdf(): void {
  this.onShowTemplateFunc(true)
    this.onSetDownloadButtonToLoading(true)

    setTimeout(() => {
      this.screenRecordService.captureScreen('voucherTemplate')
    }, 1000);
  }
  
  downloadImage(image: string): void {
    var a = document.createElement("a")
    a.href = image
    a.download = "Instrucciones de pago"
    a.click()
    this.onSetDownloadButtonToLoading(false)
  }

  uploadButton(media: MediaButton[]): void {
    this.directConnectionsVoucherInput = {
      ...this.directConnectionsVoucherInput,
      paymentMethodButtons: media,
    }
  }

  updateVoucherInstructions(instructions: InstructionStepper[]): void {
    this.directConnectionsVoucherInput = {
      ...this.directConnectionsVoucherInput,
      instructions: instructions,
    }
  }


  onShowTemplateFunc(data: boolean): void {
    this.showTemplate = data
  }

  changeProcessorInstructions(): void {
    let instruction: InstructionStepper[] = this.findInstruction()
    let buttons: MediaButton[] = []

    this.uploadButton([])

    let cashMethod = this.findCashMethod()
    let bankTransferMethod = this.findBankTransferMethod()
    let walletMethod = this.findWalletMethod()

    this.updateVoucherInstructions(instruction)

    if (bankTransferMethod) {
      buttons.push(this.bankTransferButton)
    }

    if (cashMethod) {
      buttons.push(this.cashButton)
    }

    if (walletMethod) {
      buttons.push(this.walletButton)
    }

    this.uploadButton(buttons)
  }

  paymentMethod: 'Cash' | 'BankTransfer' | 'Wallet' = 'Cash'

  get bankTransferButton(): MediaButton {
    return bankTransferPaymentMethodButton(this.paymentMethod, () => {
      this.paymentMethod = 'BankTransfer'
      this.changeProcessorInstructions()
    })

  }

  get cashButton(): MediaButton {
    return cashPaymentMethodButton(this.paymentMethod, () => {
      this.paymentMethod = 'Cash'
      this.changeProcessorInstructions()
    })
  }

  get walletButton(): MediaButton {
    return walletPaymentMethodButton(this.paymentMethod, () => {
      this.paymentMethod = 'Wallet'
      this.changeProcessorInstructions()
    })
  }


  findInstruction(): InstructionStepper[] {
    return this.instructions?.find((instruction) => instruction.paymentMethod === this.paymentMethod)?.instructions.map((instructionSelected : any) => {
      return {
        step: instructionSelected.order,
        description: instructionSelected.value,
      } as unknown as InstructionStepper
    }) || []
  }

  findCashMethod(): PaymentMethodInstruction {
    return this.findPaymentMethodInstruction('Cash') || null as any
  }

  findBankTransferMethod(): PaymentMethodInstruction {
    return this.findPaymentMethodInstruction('BankTransfer') || null as any
  }

  findWalletMethod(): PaymentMethodInstruction {
    return this.findPaymentMethodInstruction('Wallet') || null as any
  }

  findPaymentMethodInstruction(method: string): PaymentMethodInstruction | undefined {
    return this.instructions?.find((instruction) => instruction.method === method)
  }

  get instructions(): any[] {
    let instructions = this.voucherData?.voucherData?.collectorInstructions.find((bankInstruction:any) =>
       bankInstruction.collectorCode === this.selectedProcessor?.name
  )?.paymentMethodInstructions
    return instructions || []
}
}
