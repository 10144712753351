import { Component, effect, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherTemplateComponent, VoucherTemplateInput } from '@angular-monorepo/pdf';
import { InstructionStepper, LinearStepInfo } from '@angular-monorepo/shared';
import { VoucherPayinService } from '@angular-monorepo/api-services';
import { Processors } from '@angular-monorepo/processor-selection';
export enum CollectorEnum {
  'PAGO24' = 'PAGO24',
  'RAPIPAGO' = 'RAPIPAGO',
  'PAGO_FACIL' = 'PAGO FACIL',
  'COBRO_EXPRESS' = 'COBRO EXPRESS',
}
@Component({
  selector: 'payin-voucher-pdf-container',
  standalone: true,
  imports: [CommonModule,VoucherTemplateComponent],
  templateUrl: './payin-voucher-pdf-container.component.html',
  styleUrl: './payin-voucher-pdf-container.component.scss',
})
export class PayinVoucherPdfContainerComponent {
  @Input({ required: true }) linearStep: LinearStepInfo[] = []
  @Input() voucherData: any = null as unknown as any;
  @Input() layout: string = 'default'
  @Input() processors: Processors[] = [];
  @Input() copy = false;
  VoucherTemplateInput = null as any;
  pdfVoucher: VoucherTemplateInput = null as any
  linearStepInfo: LinearStepInfo[] = []
  
  constructor(
    private voucherPayinService: VoucherPayinService,
  ) {
    
    
    effect(() => {
        this.initPdfVoucher()
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['linearStep']) {
      this.linearStepInfo = changes['linearStep'].currentValue;
      this.pdfVoucher = {
        ...this.pdfVoucher,
        LinearStepInfo: this.linearStepInfo,
      }
    }
  }

  initPdfVoucher(): void {
    if(this.layout =='qr'){
      let paymentInstructions = []
       paymentInstructions.push({
      bank: 'Sigue estos pasos',
      hexColor: '#395AE8',
      instruction: [...this.voucherData.voucherData.collectorInstructions[0].paymentMethodInstructions.map((instruction:any) => {
        return instruction.instructions.map((instruction : any) => {
          return {
            step: instruction.order,
            description: instruction.value,
          } as unknown as InstructionStepper
        })
      })[0] as unknown as InstructionStepper[]]
    })

      this.pdfVoucher = {
        LinearStepInfo: this.linearStep,
        paymentInstructions: paymentInstructions
      }

    }else{
      this.pdfVoucher = {
        LinearStepInfo: this.linearStep,
        paymentInstructions: this.voucherData?.voucherData?.collectorInstructions?.map((bank :any) => {
          let collectorCode = CollectorEnum[bank.collectorCode as keyof typeof CollectorEnum] ?? bank.collectorCode
          return {
            bank: collectorCode,
            hexColor: bank.hexColor,
            instruction: [...bank.paymentMethodInstructions?.map((instruction:any) => {
              return instruction.instructions?.map((instruction : any) => {
                return {
                  step: instruction.order,
                  description: instruction.value,
                } as unknown as InstructionStepper
              })
            })[0] as unknown as InstructionStepper[]]
          } 
        })
      }
    }
    
  }
}


