export const NiubizInstructionsMobile = [
    {
        "paymentMethod": "Cash",
        "instructions": [
            {
                "order": 1,
                "value": "Descarga el QR y accede a la billetera de tu preferencia."
            },
            {
                "order": 2,
                "value": "Ingresa al lector del QR y sube la foto del QR descargada desde tu galería."
            },
            {
                "order": 3,    
                "value": "Verifica el monto a pagar a nombre de <a class='blue-text'>MONNET.</a>"
            },
            {
                "order": 4,
                "value": "Efectúa el pago y ¡listo!"
            }
        ]
    }
  ];
export const NiubizInstructionsDesktop = [
    {
        "paymentMethod": "Cash",
        "instructions": [
            {
                "order": 1,
                "value": "Accede a la billetera de tu preferencia."
            },
            {
                "order": 2,
                "value": "Escanea el QR desde el lector de tu billetera preferida."
            },
            {
                "order": 3,    
                "value": "Verifica el monto a pagar a nombre de <a class='blue-text'>MONNET.</a>"
            },
            {
                "order": 4,
                "value": "Efectúa el pago y ¡listo!​ "
            }
        ]
    }
  ] 
  
export const DeUnaInstructionsMobile = 
{
    paymentMethodInstructions:[
        {
            "paymentMethod": "Cash",
            "instructions": [
                {
                    "order": 1,
                    "value": "Haz clic en el botón PAGAR para que te redirija automáticamente a tu app de DEUNA."
                },
                {
                    "order": 2,
                    "value": "Ingresa a tu app de DEUNA con tu usuario y contraseña"
                },
                {
                    "order": 3,    
                    "value": "Verifica el monto a pagar a nombre de <a class='blue-text'>Monnet</a>."
                },
                {
                    "order": 4,
                    "value": "Efectua el pago y ¡listo!"
                }
            ]
        }
      ] 
};

export const DeUnaInstructionsDesktop = {
    paymentMethodInstructions: 
    [
        {
            "paymentMethod": "Cash",
            "instructions": [
                {
                    "order": 1,
                    "value": "Abre tu aplicación de DEUNA"
                },
                {
                    "order": 2,
                    "value": "Escanea el QR desde el lector de tu billetera de DEUNA"
                },
                {
                    "order": 3,    
                    "value": "Verifica el monto a pagar a nombre de <a class='blue-text'>MONNET</a>."
                },
                {
                    "order": 4,
                    "value": "Efectúa el pago y ¡listo!"
                }
            ]
        }
      ]
}
