import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutPayinVoucherContainerComponent } from '@angular-monorepo/checkout-payin-voucher';

@Component({
  selector: 'angular-monorepo-voucher',
  standalone: true,
  imports: [CommonModule,CheckoutPayinVoucherContainerComponent],
  templateUrl: './voucher.component.html',
  styleUrl: './voucher.component.scss',
})
export class VoucherComponent {}
