import { Route } from '@angular/router';
import { VoucherComponent } from './pages/voucher/voucher.component';
import { ErrorComponent } from './pages/error/error.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export const appRoutes: Route[] = [
      {
        path: 'payin-voucher/:operationId',
        component: VoucherComponent,
      },
      {
        path: 'error',
        component: ErrorComponent
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
];
