import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Processors, ProcessorStaticViewComponent } from '@angular-monorepo/processor-selection';
import { PvQrHeaderComponent } from '../pv-qr-header/pv-qr-header.component';
import { VoucherPayinIntructionsComponent } from '../../voucher-payin-intructions/voucher-payin-intructions.component';
import { CardComponent } from '@angular-monorepo/ui';
import { Banner, BannerComponent, LinearStepFontSize, LinearStepInfo, LinearStepStatusColor } from '@angular-monorepo/shared';
import { ResponsiveService } from '../../../../../../shared/src/lib/services/responsive.service';

@Component({
  selector: 'qr-payin-voucher',
  standalone: true,
  imports: [CommonModule, CardComponent, PvQrHeaderComponent, ProcessorStaticViewComponent, BannerComponent, VoucherPayinIntructionsComponent],
  templateUrl: './qr-payin-voucher.component.html',
  styleUrl: './qr-payin-voucher.component.scss',
  providers: [ResponsiveService]
})
export class QrPayinVoucherComponent {
  esResponsive$ = this.responsiveService.esResponsive$;
  @Input() voucherData: any;
  linearSteps: LinearStepInfo[] = [];
  selectedProcessor: Processors;
  processors: Processors[] = [];
  title: string;
  titleExpiration: string;
  constructor(private responsiveService: ResponsiveService) { }

  ngOnChanges(): void {
    this.initLinearSteps()
    this.initProcessors()
  }

  initProcessors(): void {
    if (this.voucherData?.processor.parent !== 'Deuna') {
      this.processors = this.voucherData?.voucherData?.collectorInstructions.map((bank: any) => {
        return {
          name: bank?.collectorCode || '',
          img: `https://payin-checkout-config.s3.amazonaws.com/processors/${this.voucherData?.processor.parent === 'Gmoney' ? 'Gmoney' : 'Niubiz'}/collectors/${bank?.collectorCode}/ACTIVE.png`,
          hexColor: '#404FE2',
        } as unknown as Processors

      }) || []

      this.selectedProcessor = this.processors[0]
    }
  }

  get banner(): Banner {
    if (this.voucherData?.processor.parent === 'Deuna') {
      return {
        processor: '',
        text: 'Estás pagando',
        img: `./assets/svg/deuna.svg`,
        background: '#442E4C',
      };
    } else {
      return {
        processor: '',
        text: 'Sigue estos pasos',
        img: '',
        background: '#404FE2',
      };
    }

  }
  ngOnInit(): void {
    this.esResponsive$.subscribe((esResponsive) => {
      this.title = esResponsive ? 'Descarga el QR, súbelo a tu galería y págalo desde tu billetera favorita' : 'Escanea el QR y págalo desde tu billetera favorita'

      if (this.voucherData?.processor.parent === 'Deuna') {
        this.titleExpiration = this.voucherData?.operation?.status == '5' || this.voucherData?.operation?.status == '3' ? 'Estado' : 'Vence en'
      } else {
        this.titleExpiration = esResponsive ? 'Tu QR expirará el' : 'Tu transacción expirará el'
      }
      this.initLinearSteps()
    });
  }

  initLinearSteps(): void {
    const steps = []


    const code: LinearStepInfo = {
      label: {
        label: this.title,
        value: this.voucherData?.voucherData?.qrCode,
        color: LinearStepStatusColor.BLUE,
        fontSize: LinearStepFontSize.SMALL,
        row: true,
        img: true
      },
    }

    const amount: LinearStepInfo = {
      label: {
        label: 'Monto a pagar',
        value: `<b> ${this.voucherData?.operation?.symbolCurrency} ${this.voucherData?.operation?.amount}</b>`,
        fontSize: LinearStepFontSize.SMALL,
      },
    }

    let date = this.setDate();

    steps.push(code)
    steps.push(amount)
    steps.push(date)
    this.linearSteps = steps
  }


  setDate() {
    let date;
    let esMenor = false;


    let expirationDateString = this.voucherData?.operation?.expirationDate

    if (expirationDateString) {
      let expirationDate = new Date(expirationDateString);
      esMenor = expirationDate.getTime() < new Date().getTime();
    }

    if (this.voucherData.operation.status == '5') {
      date = {
        label: {
          label: 'Estado',
          value: `<b>Pago <br> exitoso</b>`,
          color: LinearStepStatusColor.GREEN,
          fontSize: LinearStepFontSize.SMALL,
        },
      }
      return date;
    } if (this.voucherData.operation.status == '3') {
      date = {
        label: {
          label: 'Estado',
          value: `<b>Pago <br> expirado</b>`,
          color: LinearStepStatusColor.RED,
          fontSize: LinearStepFontSize.SMALL,
        },
      }
      return date;
    } else if (esMenor) {
      date = {
        label: {
          label: 'Estado',
          value: `<b>Pago <br> expirado</b>`,
          color: LinearStepStatusColor.RED,
          fontSize: LinearStepFontSize.SMALL,
        },
      }
      return date;
    } else {
      date = {
        label: {
          label: this.titleExpiration,
          value: `<b>${this.formatDate(this.voucherData?.operation?.expirationDate)}</b> a las 23:30`,
          color: LinearStepStatusColor.BLUE,
          fontSize: LinearStepFontSize.SMALL,
        }
      }
    }
    return date;
  }

  formatDate(dateString: string) {
    if (!dateString) {
      return ''
    }

    const [datePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-');

    return `${day}/${month}/${year}`;
  }
}

