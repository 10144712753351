import { Component, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultPayinVoucherComponent } from '../default-voucher/default-payin-voucher/default-payin-voucher.component';
import { BetssonPayinVoucherComponent } from '../betsson-payin-voucher/betsson-payin-voucher.component';
import { QrPayinVoucherComponent } from '../qr-voucher/qr-payin-voucher/qr-payin-voucher.component';
import { GetPaymentPayloadVoucherRequest, VoucherPayinService } from '@angular-monorepo/api-services';
import { ActivatedRoute } from '@angular/router';
import { CustomSpinnerComponent, VoucherResponse, Instruction } from '@angular-monorepo/ui';
import { ErrorPageComponent } from '@angular-monorepo/shared';
import { ResponsiveService } from 'libs/shared/src/lib/services/responsive.service';

@Component({
  selector: 'checkout-payin-voucher-container',
  standalone: true,
  imports: [
    CommonModule,
    DefaultPayinVoucherComponent,
    BetssonPayinVoucherComponent,
    QrPayinVoucherComponent,
    CustomSpinnerComponent,
    ErrorPageComponent,
  ],
  templateUrl: './checkout-payin-voucher-container.component.html',
  styleUrl: './checkout-payin-voucher-container.component.scss',
  providers: [ResponsiveService],
})
export class CheckoutPayinVoucherContainerComponent {
  esResponsive$ = this.responsiveService.esResponsive$;
  voucherData: VoucherResponse;
  isLoading: boolean = false;
  paymentParemeters: GetPaymentPayloadVoucherRequest =
    {} as GetPaymentPayloadVoucherRequest;
  componenteActivo: 'betsson' | 'default' | 'qr' | 'error' | null = null;
  intervalRef: number;

  constructor(
    private voucherPayinService: VoucherPayinService,
    private activatedRoute: ActivatedRoute,
    private responsiveService: ResponsiveService
  ) {
    effect(() => {
      this.isLoading = this.voucherPayinService.paymentLoading()
      if (this.voucherPayinService.paymentData()) {
        this.voucherData = this.voucherPayinService.paymentData();

        this.setComponenteActivo()

      }
      if (this.voucherPayinService.getPaymentError() || this.voucherPayinService.paymentData().errorMsg) {
        this.isLoading = this.voucherPayinService.paymentLoading()
      }
    })
  }

  ngOnInit(): void {
    this.paymentParemeters = {
      operationId: this.activatedRoute.snapshot.params['operationId'],
      verification: this.activatedRoute.snapshot.queryParams['verification'],
    }

    this.getVoucherDetails(this.paymentParemeters)
  }


  getVoucherDetails(data: GetPaymentPayloadVoucherRequest): void {
    this.voucherPayinService.getVoucherDetails(data);
  }

  refreshVoucher(): void {

    if (this.intervalRef) { clearInterval(this.intervalRef); }

    if (this.componenteActivo !== 'error' && this.componenteActivo !== 'betsson') {
      this.intervalRef = setInterval(() => {
        this.getVoucherDetails(this.paymentParemeters)
      }, 60000);
    }

  }

  setComponenteActivo(): void {
    if (this.voucherData?.voucherData?.voucherTemplate === 'default' &&
      (this.voucherData?.processor.parent === 'Niubiz' || this.voucherData?.processor.parent === 'Deuna' || this.voucherData?.processor.parent === "Gmoney")
    ) {
      this.componenteActivo = 'qr';
      this.esResponsive$.subscribe((res) => {
        const desktopInstructions = this.getInstructions('DESKTOP');
        const mobileInstructions = this.getInstructions('MOBILE') || desktopInstructions;
        const instructions = res ? mobileInstructions : desktopInstructions;
        this.voucherData.voucherData.collectorInstructions[0].paymentMethodInstructions = mobileInstructions ? [{ paymentMethod: 'Cash', instructions }] : [];
      });
    } else if (this.voucherData?.voucherData?.voucherTemplate === 'default') {
      this.componenteActivo = 'default'
    } else if (this.voucherData?.voucherData?.voucherTemplate === 'voucher_betsson') {
      this.componenteActivo = 'betsson'
    } else {
      this.componenteActivo = 'error'
    }

    this.refreshVoucher()
  }

  private getInstructions(deviceType: string): Instruction[] {
    const generalInstructions =
      this.voucherData?.voucherData?.generalInstructions?.find((instruction) =>
        instruction?.devices?.find((device) => device.code === deviceType)
      );

    return generalInstructions?.devices?.find(
      (device) => device.code === deviceType
    )?.instructions as Instruction[];
  }

  ngOnDestroy(): void {
    if (this.intervalRef) { clearInterval(this.intervalRef); }
  }


}


