import { Component, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultPayinVoucherComponent } from '../default-voucher/default-payin-voucher/default-payin-voucher.component';
import { BetssonPayinVoucherComponent } from '../betsson-payin-voucher/betsson-payin-voucher.component';
import { QrPayinVoucherComponent } from '../qr-voucher/qr-payin-voucher/qr-payin-voucher.component';
import { GetPaymentPayloadVoucherRequest, VoucherPayinService } from '@angular-monorepo/api-services';
import { ActivatedRoute } from '@angular/router';
import { CustomSpinnerComponent } from '@angular-monorepo/ui';
import { ErrorPageComponent } from '@angular-monorepo/shared';
import { ResponsiveService } from 'libs/shared/src/lib/services/responsive.service';
import { DeUnaInstructionsDesktop, DeUnaInstructionsMobile, NiubizInstructionsDesktop, NiubizInstructionsMobile } from 'libs/checkout-payin-voucher/src/static/instructions';

@Component({
  selector: 'checkout-payin-voucher-container',
  standalone: true,
  imports: [CommonModule, DefaultPayinVoucherComponent, BetssonPayinVoucherComponent,
    QrPayinVoucherComponent, CustomSpinnerComponent, ErrorPageComponent],
  templateUrl: './checkout-payin-voucher-container.component.html',
  styleUrl: './checkout-payin-voucher-container.component.scss',
  providers: [ResponsiveService],
})
export class CheckoutPayinVoucherContainerComponent {
  esResponsive$ = this.responsiveService.esResponsive$;
  voucherData: any = null;
  isLoading: any;
  paymentParemeters: GetPaymentPayloadVoucherRequest = {} as GetPaymentPayloadVoucherRequest;
  componenteActivo: "betsson" | "default" | "qr" | 'error' | null = null;
  intervalRef: any;
  constructor(
    private voucherPayinService: VoucherPayinService,
    private activatedRoute: ActivatedRoute,
    private responsiveService: ResponsiveService

  ) {
    effect(() => {
      this.isLoading = this.voucherPayinService.paymentLoading()
      if (this.voucherPayinService.paymentData()) {
        this.voucherData = this.voucherPayinService.paymentData();

        this.setComponenteActivo()

      }
      if (this.voucherPayinService.getPaymentError() || this.voucherPayinService.paymentData().errorMsg) {
        this.isLoading = this.voucherPayinService.paymentLoading()
      }
    })
  }

  ngOnInit(): void {
    this.paymentParemeters = {
      operationId: this.activatedRoute.snapshot.params['operationId'],
      verification: this.activatedRoute.snapshot.queryParams['verification'],
    }

    this.getVoucherDetails(this.paymentParemeters)
  }


  getVoucherDetails(data: GetPaymentPayloadVoucherRequest): void {
    this.voucherPayinService.getVoucherDetails(data);
  }

  refreshVoucher(): void {

    if (this.intervalRef) { clearInterval(this.intervalRef); }

    if (this.componenteActivo !== 'error' && this.componenteActivo !== 'betsson') {
      this.intervalRef = setInterval(() => {
        this.getVoucherDetails(this.paymentParemeters)
      }, 60000);
    }

  }

  setComponenteActivo(): void {


    if (this.voucherData?.voucherData?.voucherTemplate === 'default' &&
      (this.voucherData?.processor.parent === 'Niubiz' || this.voucherData?.processor.parent === 'Deuna')
    ) {
      this.componenteActivo = 'qr'
      this.esResponsive$.subscribe((res) => {
        if (this.voucherData?.processor.parent === 'Niubiz') {
          if (res) {
            this.voucherData.voucherData.collectorInstructions[0].paymentMethodInstructions = NiubizInstructionsMobile;
          } else {
            this.voucherData.voucherData.collectorInstructions[0].paymentMethodInstructions = NiubizInstructionsDesktop;
          }
        }

        if (this.voucherData?.processor.parent === 'Deuna') {
          if (res) {
            this.voucherData.voucherData.collectorInstructions[0] = DeUnaInstructionsMobile;
          } else {
            this.voucherData.voucherData.collectorInstructions[0] = DeUnaInstructionsDesktop;

          }
        }


      }
      )

    } else if (this.voucherData?.voucherData?.voucherTemplate === 'default') {
      this.componenteActivo = 'default'
    } else if (this.voucherData?.voucherData?.voucherTemplate === 'voucher_betsson') {
      this.componenteActivo = 'betsson'
    } else {
      this.componenteActivo = 'error'
    }

    this.refreshVoucher()
  }


  ngOnDestroy(): void {
    if (this.intervalRef) { clearInterval(this.intervalRef); }
  }


}


