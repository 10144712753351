<ui-card [voucherCard]="true">
  <div class="title" slot="card_title">
    <pv-default-header [title]="'Información de pago'" [voucherData]="voucherData"
      [linearSteps]="linearSteps"></pv-default-header>
  </div>
  <div class="content" slot="card_content">

    <ng-container
      *ngIf="selectedProcessor && selectedProcessor.name && processors.length > 1 && (voucherData?.voucherConfig?.version === undefined || voucherData?.voucherConfig?.version === 'V1')">
      <div class="processor-selectable">
        <ps-processor-selectable [processors]="processors" [selectedProcessor]="selectedProcessor.name"
          (onSelectProcessor)="onProcessorChange($event)" [voucherPayin]="true"></ps-processor-selectable>
      </div>
    </ng-container>

    <ng-container *ngIf="voucherData?.voucherConfig?.version === 'V2'">
      <ps-processor-networks [config]="voucherData?.voucherConfig"
        (onSelectProcessor)="onProcessorChange($event)"></ps-processor-networks>
    </ng-container>

    <sh-banner [bannerInput]="banner"></sh-banner>

    <voucher-payin-intructions [voucherData]="voucherData" [linearSteps]="linearSteps"
      [selectedProcessor]="selectedProcessor" [layout]="'default'"></voucher-payin-intructions>

  </div>
  <div class="actions" slot="card_actions">
    <div class="logotipe">
      <img src="./assets/svg/powered-by-monnet-payment-solutions.svg" alt="">
    </div>
  </div>
</ui-card>