@if(isLoading) {
  <div class="spinner">
    <ui-custom-spinner [image]="'./assets/img/favicon.ico'" ></ui-custom-spinner>
  </div>
  } @else {
    <div  class="container" [ngClass]="{ 'auto': componenteActivo !== 'betsson', 'container__flex': componenteActivo !== 'betsson', 'fondo-gris': componenteActivo === 'betsson' }">
      @if(componenteActivo === 'default'){
      <default-payin-voucher  [voucherData]="voucherData"></default-payin-voucher>
      } @if (componenteActivo === 'betsson') {
      <betsson-payin-voucher [voucherData]="voucherData" ></betsson-payin-voucher>
      } @if(componenteActivo === 'qr'){
      <qr-payin-voucher [voucherData]="voucherData" ></qr-payin-voucher>
      } @if(componenteActivo === 'error'){
        <div class="container">
          <sh-error-page></sh-error-page>
        </div>
      }

  </div>
  }




  
  

    