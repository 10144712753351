<ui-card [voucherCard]="true">
  <div class="title" slot="card_title">
    <pv-default-header [title]="'Información de pago'" [voucherData]="voucherData"
      [linearSteps]="linearSteps"></pv-default-header>
  </div>
  <div class="content" slot="card_content">

    <div class="processor-selectable" *ngIf="selectedProcessor && selectedProcessor.name">
      <ps-processor-selectable *ngIf="processors.length > 1 " [processors]="processors" [selectedProcessor]="selectedProcessor.name"
        (onSelectProcessor)="onProcessorChange($event)" [voucherPayin]="true">
      </ps-processor-selectable>
    </div>

    <sh-banner [bannerInput]="banner"></sh-banner>

    <voucher-payin-intructions [voucherData]="voucherData" [linearSteps]="linearSteps"
      [selectedProcessor]="selectedProcessor" [layout]="'default'"></voucher-payin-intructions>

  </div>
  <div class="actions" slot="card_actions">
    <div class="logotipe">
      <img src="./assets/svg/powered-by-monnet-payment-solutions.svg" alt="">
    </div>
  </div>
</ui-card>