<ui-card [voucherCard]="true">
    <div class="title" slot="card_title">
      <pv-qr-header [voucherData]="voucherData" [title]="'Información de pago'" [linearSteps]="linearSteps"></pv-qr-header>
    </div>
    <div class="content" slot="card_content">
      <ps-processor-static-view [processors]="processors"></ps-processor-static-view>
      <sh-banner [bannerInput]="banner"></sh-banner>
      <voucher-payin-intructions [voucherData]="voucherData" [linearSteps]="linearSteps" [selectedProcessor]="selectedProcessor" [processors]="processors" [layout]="'qr'"></voucher-payin-intructions>
    </div>
    <div class="actions" slot="card_actions">
      <div class="logotipe">
        <img src="./assets/svg/powered-by-monnet-payment-solutions.svg" alt="">
      </div>
    </div>
  </ui-card>