<div id="voucher-betsson" #contenedor class="voucher">
  <div class="voucher__titulo">
    <img src="./assets/voucher-betsson/logo.png" alt="logo" />
  </div>

  <div class="voucher__orden">
    <p class="voucher__orden__item">ID Transacción: {{voucherData.operation.merchantOperationNumber}}</p>
    <p class="voucher__orden__item">Orden de pago en efectivo</p>
    <p class="voucher__orden__item">Ticket: {{voucherData.voucherData.ticketNumber}}</p>
  </div>

  <div class="voucher__total">
    <h1>$ {{formatAmount(voucherData.operation.amount)}}</h1>
  </div>

  <div class="voucher__codigo-barras" >
    <img id="code" [src]="processedImage" alt="Código de barras" />
  </div>

  <div class="voucher__codigo">
    <h5 style="font-weight: 600;">Código de Pago</h5>
  </div>

  <div class="voucher__vencimiento">
    <p class="voucher__vencimiento__titulo" >Este código debe ser pagado antes de:</p>
    <p class="voucher__vencimiento__fecha">{{voucherData.voucherData.expirationDate}}</p>
  </div>

  <div class="voucher__accionadores">
    <div class="voucher__accionadores__accionador" (click)="onImprimir()">
      <img src="./assets/voucher-betsson/impresora.jpeg" alt="Imprimir" />
      <h5>{{ (esResponsive$ | async) ? 'Imprimir' : 'Imprimir Voucher' }}</h5>
    </div>

    <div class="voucher__accionadores__accionador" (click)="onDescargar()">
      <img src="./assets/voucher-betsson/camara.jpeg" alt="Descargar" />
      <h5>Descargar</h5>
    </div>
  </div>

  <div class="voucher__instrucciones">
    <h5><strong>Instrucciones</strong></h5>
    <ol class="voucher__instrucciones__listado">
      <li> <span class="voucher__instrucciones__listado--margin"></span> Descargá o imprimí este voucher. </li>
      <li> <span class="voucher__instrucciones__listado--margin"></span>Acercate al punto de pago elegido.</li>
      <li> <span class="voucher__instrucciones__listado--margin"></span>Indicá que vas a pagar el servicio de <strong>Cobro Digital</strong>.</li>
      <li> <span class="voucher__instrucciones__listado--margin"></span>Mostrá el código de barras para que sea escaneado.</li>
      <li> <span class="voucher__instrucciones__listado--margin"></span>El pago será acreditado en tu cuenta Betsson automáticamente según el punto de pago elegido.</li>
    </ol>
  </div>
</div>
