import { Component, ElementRef, Input, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResponsiveService } from 'libs/shared/src/lib/services/responsive.service';
import html2canvas from 'html2canvas';

@Component({
  selector: 'betsson-payin-voucher',
  standalone: true,
  imports: [CommonModule],
  providers: [ResponsiveService],
  templateUrl: './betsson-payin-voucher.component.html',
  styleUrl: './betsson-payin-voucher.component.scss',
})
export class BetssonPayinVoucherComponent {
  @Input() voucherData: any;
  @ViewChild('contenedor', { static: true }) contenedor: ElementRef;
  esResponsive$ = this.responsiveService.esResponsive$;
  base64String: string = '';  // Reemplaza esto con tu cadena base64
  processedImage: string | null = null;
  constructor(
    private readonly responsiveService: ResponsiveService,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
     this.base64String = this.voucherData.voucherData.barCode;
 
     this.removeWhiteBackground(this.base64String);
  }

   onDescargar() {
    html2canvas(this.contenedor.nativeElement).then((canvas) => {
      const anchorTag = this.renderer.createElement('a');
      this.renderer.setAttribute(anchorTag, 'download', 'voucher.png');
      this.renderer.setAttribute(anchorTag, 'href', canvas.toDataURL('image/png'));
      this.renderer.setAttribute(anchorTag, 'target', '_blank');
      anchorTag.click();
      this.renderer.removeChild(document.body, anchorTag);
    });
  }

  onImprimir() {
    window.print();
  }


  removeWhiteBackground(base64: string) {
  
    const image = new Image();
    image.src = 'data:image/png;base64,' + base64;
  
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);


      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

     
      for (let i = 0; i < data.length; i += 4) {
        if (data[i] === 255 && data[i + 1] === 255 && data[i + 2] === 255) {
         
          data[i + 3] = 0;
        }
      }

     
      ctx.putImageData(imageData, 0, 0);

      
      this.processedImage = canvas.toDataURL('image/png');
    };
  }

  formatAmount(amount:string) {
    const [integerPart, decimalPart] = amount.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    return `${formattedIntegerPart}.${decimalPart}`;
  }
}



