import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment';
import { EnvironmentService } from '@angular-monorepo/environment';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(appRoutes),
    { provide: MatDialog, useValue: MatDialog },
    importProvidersFrom(MatDialogModule, MatIconModule, MatButtonModule, MatCardModule, MatSnackBarModule, BrowserAnimationsModule, HttpClientModule,),
    {
        provide: APP_INITIALIZER,
        useFactory: (configService: EnvironmentService) => () => configService.setConfig(environment),
        deps: [EnvironmentService],
        multi: true
    }, provideAnimations()],
};
