import { Component, effect, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from '@angular-monorepo/ui';
import { Banner, BannerComponent, LinearStepFontSize, LinearStepInfo, LinearStepStatusColor } from '@angular-monorepo/shared';
import { ProcessorNetworkseComponent, Processors, ProcessorSelectableComponent } from '@angular-monorepo/processor-selection';
import { PvDefaultHeaderComponent } from '../pv-default-header/pv-default-header.component';
import { VoucherPayinService } from '@angular-monorepo/api-services';
import { ActivatedRoute, Router } from '@angular/router';
import { VoucherPayinIntructionsComponent } from '../../voucher-payin-intructions/voucher-payin-intructions.component';

@Component({
  selector: 'default-payin-voucher',
  standalone: true,
  imports: [CommonModule, CardComponent, PvDefaultHeaderComponent,
    BannerComponent, ProcessorSelectableComponent, VoucherPayinIntructionsComponent,
    ProcessorNetworkseComponent
  ],
  templateUrl: './default-payin-voucher.component.html',
  styleUrl: './default-payin-voucher.component.scss',
})
export class DefaultPayinVoucherComponent {
  @Input() voucherData: any = null as unknown as any;;
  isLoading = false;
  paymentParemeters: any = {} as any;
  selectedProcessor: Processors = null as any
  linearSteps: LinearStepInfo[] = []
  processors: Processors[] = []

  get banner(): Banner {
    return {
      processor: this.selectedProcessor?.name,
      text: 'Estás Pagando con',
      img: this.selectedProcessor?.img,
      background: this.selectedProcessor?.hexColor || '',
    };
  }

  constructor(
    private voucherPayinService: VoucherPayinService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    effect(() => {
      this.isLoading = this.voucherPayinService.paymentLoading()
      this.refreshVoucher()
    })


  }

  ngOnInit() {
    this.paymentParemeters = {
      operationId: this.activatedRoute.snapshot.params['operationId'],
      merchantId: this.activatedRoute.snapshot.params['merchantId'],
      verification: this.activatedRoute.snapshot.queryParams['verification'],
    }


  }

  refreshVoucher(): void {
    this.initLinearSteps()
    this.initProcessors()
  }

  initProcessors(): void {
    this.processors = this.voucherData?.voucherData?.collectorInstructions.map((bank: any) => {
      return {
        name: bank?.collectorCode || '',
        img: `https://payin-checkout-config.s3.amazonaws.com/processors/CobroDigital/collectors/${bank?.collectorCode}/${bank?.collectorCode == 'PAGO_FACIL' ? 'INACTIVE' : 'ACTIVE'}.png`,
        hexColor: bank?.hexColor || '',
      } as unknown as Processors

    }) || []
    this.selectedProcessor = this.processors[0]
  }

  initLinearSteps(): void {
    const steps = []

    const code: LinearStepInfo = {
      label: {
        label: 'Código de pago',
        value: 'data:image/png;base64,' + this.voucherData?.voucherData?.barCode,
        color: LinearStepStatusColor.BLUE,
        fontSize: LinearStepFontSize.LARGE,
        row: true,
        img: true
      },
    }

    const amount: LinearStepInfo = {
      label: {
        label: 'Monto a pagar',
        value: `<b> $ ${this.voucherData?.operation?.amount}</b>`,
        fontSize: LinearStepFontSize.SMALL,
      },
    }

    let date = this.setDate();

    steps.push(code)

    steps.push(amount)
    steps.push(date)


    this.linearSteps = steps
  }

  setDate() {
    let date;
    let esMenor = false;


    let expirationDateString = this.voucherData?.operation?.expirationDate;

    if (expirationDateString) {
      let expirationDate = new Date(expirationDateString);
      esMenor = expirationDate.getTime() < new Date().getTime();
    }


    if (this.voucherData?.operation?.status == '5') {
      date = {
        label: {
          label: 'Estado',
          value: `<b>Pago exitoso</b>`,
          color: LinearStepStatusColor.GREEN,
          fontSize: LinearStepFontSize.LARGE,
        },
      }
    } else if (esMenor) {
      date = {
        label: {
          label: 'Estado',
          value: `<b>Pago expirado</b>`,
          color: LinearStepStatusColor.RED,
          fontSize: LinearStepFontSize.LARGE,
        },
      }
    } else {
      date = {
        label: {
          label: `Fecha de vencimiento`,
          value: `<b>${this.formatDate(this.voucherData?.operation?.expirationDate)}</b>`,
          color: LinearStepStatusColor.BLUE,
          fontSize: LinearStepFontSize.SMALL,
        }
      }
    }

    return date;
  }

  onProcessorChange(processor: Processors): void {
    this.selectedProcessor = processor;
  }

  formatDate(dateString: string) {
    // Dividir la fecha y la hora
    const [datePart] = dateString.split(' ');

    // Dividir la parte de la fecha en año, mes y día
    const [year, month, day] = datePart.split('-');

    // Formatear la fecha en 'DD/MM/YYYY'
    return `${day}/${month}/${year}`;
  }


}

